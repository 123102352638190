import { navigateTo } from './navigation'
import { loader, authenticationTools } from './helper'
import authToken from './authToken'
import { getAuthToken } from './authToken'

export type Pong = { status: 'ok' | 'ko'; version: string }

let apiUrl = createApiUrl()

function isHttp() {
    return document.location.href.indexOf('http://') == 0
}

function isLocalHost() {
    const hostName = document.location.hostname as string

    if (hostName.includes('capla-dev.celine.net')) {
        return true
    }

    return document.location.hostname.indexOf('localhost') != -1
}

function checkHttps() {
    if (isHttp() && !isLocalHost()) {
        document.location.href = document.location.href.replace('http://', 'https://')
    }
}

function createApiUrl() {
    if (isLocalHost()) {
        return 'http://localhost:61402'
    } else {
        switch (document.location.hostname) {
            case 'capla-int.celine.net':
                return 'https://rtwsupplierportal-api-int.azurewebsites.net'
            case 'capla-rec.celine.net':
                return 'https://rtwsupplierportal-api-rec.azurewebsites.net'
            case 'capla-uat.celine.net':
                return 'https://rtwsupplierportal-api-uat.azurewebsites.net'
            case 'capla.celine.net':
                return 'https://rtwsupplierportal-api-prod.azurewebsites.net'
            default:
                return `https://${document.location.hostname.replace('-', '-api-')}`
        }
    }
}

function get<T>(path: string, parameter?: any, token?: string): Promise<T> {
    return parameter
        ? handleFetch(path + createQueryParameters(), { method: 'get' }, token)
        : handleFetch(path, { method: 'get' }, token)

    function createQueryParameters() {
        let parameters = new Array<string>()
        for (let prop in parameter) {
            parameters.push(prop + '=' + parameter[prop])
        }
        return '?' + parameters.join('&')
    }
}

function post<T>(path: string, parameter: T,signal?: AbortSignal) {
    return handleFetch(path, { method: 'post', body: JSON.stringify(parameter) }, undefined, signal)
}

function del<T>(path: string, parameter: any) {
    return handleFetch(path, { method: 'delete', body: JSON.stringify(parameter) })
}

function ping() {
    return get<Pong>('ping')
}

function handleFetch(path: string, input: any, token?: string, abortSignal?: AbortSignal) {
    let isOfflineStatus = response => !response.status
    let isOfflineResponse = json => json && json.status === 'Offline'

    const accessToken = getAuthToken()

    async function makeRequest() {
        input.headers = {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
        }
        input.headers['Authorization'] = `Bearer ${path.includes('userInfo') ? token : accessToken}`

        if(abortSignal){
            input.signal = abortSignal;
        }

        try {
            let response = await fetch(encodeURI(apiUrl + '/' + path), input)
            if (response.status != 200) throw response
            return await response.json().catch(_ => {})
        } catch (ex) {
            if (ex.status === 401) {
                // navigateTo('/')
            } else {
                console.error(ex)
            }
            throw ex
        }
    }

    let request = makeRequest()
    loader.displayProgressFor(request)
    return request
}

checkHttps()

export default { get, post, del, ping, apiUrl }
