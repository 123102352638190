import { observer } from 'mobx-react'
import React from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { MuiProps, muiOptions, defaultStyles } from '../materialUiThemeProvider'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    FormControlLabel,
    Switch,
    Tooltip,
    Button,
    CircularProgress,
    Menu,
    MenuItem,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { observable, makeAutoObservable, runInAction } from 'mobx'
import * as Icons from '@material-ui/icons'
import filtersState from './filters/filterGlobalState'
import asnGlobalFiltersState from './asnFilters/asnGlobalFiltersState'
import Filters from './filters/components/filters'
import AdditionalFilters from './additionalFilters'
import AdditionalTools from './additionalTools'
import Accumulator from './filters/components/accumulator'
import { state as filterState } from './filters/components/filters'
import { state as asnFilters } from './asnFilters/components/asnFilters'

import filterHelper from './filters/filterHelper'
import { t } from 'i18next'
import { componentDidUpdate } from 'react-addons-linked-state-mixin'
import { FilterAsn, FiltersPost, OrdreDeFabricationFiltered } from '../../ordreDeFabrication/models'
import filterDatePicker from './filters/components/filterDatePicker'
import { showNotification } from '../notifications'
import api from '../api'
import authToken from '../authToken'
import { AnyNsRecord } from 'dns'

class ToolbarState {
    @observable isExpended = false
    @observable isLoading = false
    @observable isLoadingAsn = false
    @observable body: any
    @observable anchorEl: any = null
    @observable anchorElFav: any = null

    constructor() {
        makeAutoObservable(this)
    }
}
let toolbarState = new ToolbarState()

let removeStorageVariable = async (key: string) => {
    toolbarState.anchorElFav = null
    localStorage.removeItem(key)
    let result = filterHelper.filtersFlat(filterState.appliedFilters)

    let body: FiltersPost = {
        ColorCode: [],
        ManufacturerCode: [],
        ShopCode: [],
        Number: [],
        SeasonCode: [],
        TypeWO: [],
        Group: [],
        Status: [],
        SubActivityCode: [],
        TypeCode: [],
        Gender: [],
        ModelCode: [],
        MaterialCode: [],
    }
    result.forEach(x => {
        switch (x.type) {
            case 'color':
                body.ColorCode.push(x.key)
                break
            case 'manufacturer':
                body.ManufacturerCode.push(x.key)
                break
            case 'shop':
                body.ShopCode.push(x.key)
                break
            case 'season':
                body.SeasonCode.push(x.key)
                break
            case 'typeWO':
                body.TypeWO.push(x.key)
                break
            case 'group':
                body.Group.push(x.key)
                break
            case 'status':
                body.Status.push(x.key)
                break
            case '#WO':
                body.Number.push(x.key)
                break
            case 'subActivity':
                body.SubActivityCode.push(x.key)
                break
            case 'type':
                body.TypeCode.push(x.key)
                break
            case 'gender':
                body.Gender.push(x.key)
                break
            case 'model':
                body.ModelCode.push(x.key)
                break
            case 'material':
                body.MaterialCode.push(x.key)
                break
        }
    })
    await filterHelper.loadWantedWo(body)
    const ofs = filterHelper.filterOpenedWo(filtersState.ordreDeFabricationsTotal.map(x => jQuery.extend({}, x)))
    filterHelper.resetFilters()
    filterHelper.updateFilters(filtersState.filters)
    filtersState.ordreDeFabricationsToDisplay = ofs
}

let filterWithFavorite = async (key: string) => {
    toolbarState.anchorElFav = null
    const data = window.localStorage.getItem(key)
    filterHelper.resetFilters()
    let parse = data ? JSON.parse(data) : ''
    filterState.appliedFilters = parse
    toolbarState.body = parse
    let result = filterHelper.filtersFlat(filterState.appliedFilters)
    if (data) {
        let body: FiltersPost = {
            ColorCode: [],
            ManufacturerCode: [],
            ShopCode: [],
            Number: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            Status: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
        }
        result.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'manufacturer':
                    body.ManufacturerCode.push(x.key)
                    break
                case 'shop':
                    body.ShopCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.Status.push(x.key)
                    break
                case '#WO':
                    body.Number.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })
        await filterHelper.loadWantedWo(body)
        const ofs = filterHelper.filterOpenedWo(filtersState.ordreDeFabricationsTotal.map(x => jQuery.extend({}, x)))
        filterHelper.resetFilters()
        filterHelper.updateFilters(filtersState.filters)
        filtersState.ordreDeFabricationsToDisplay = ofs
    }
}

let saveFavorite = () => {
    if (toolbarState.body) filtersState.favorite = toolbarState.body
    else filtersState.favorite = {} as FiltersPost

    const keyPattern = 'MY_FAVORITES'
    const keys = Object.keys(localStorage).filter(key => key.startsWith(keyPattern))

    let maxIndex = 0
    keys.forEach(key => {
        const index = parseInt(key.replace(keyPattern, ''))
        if (index > maxIndex) {
            maxIndex = index
        }
    })

    const newIndex = maxIndex + 1
    const newKey = `${keyPattern}${newIndex}`
    if (!toolbarState.body) {
        showNotification('You need to chose at least one filter!', 'warning')
    } else {
        window.localStorage.setItem(newKey, JSON.stringify(filtersState.favorite))
        showNotification('Filters saved!', 'success')
    }
}

let Toolbar = observer((props: MuiProps) => {
    let classes = props.classes

    function getFavorites(): { key: string; value: string }[] {
        let results: { key: string; value: string }[] = []
        const keys = Object.keys(localStorage).filter(key => key.startsWith('MY_FAVORITES'))

        for (let i = 0; i < keys.length; i++) {
            let data = window.localStorage.getItem(keys[i])
            let parse = data ? JSON.parse(data) : ''
            let result = filterHelper.filtersFlat(parse)
            let resultString = result
                .map(x => {
                    return x.key + ' | '
                })
                .join(' ')
            let resultKey = { key: keys[i], value: resultString }
            results.push(resultKey)
        }
        // results = results.filter((value, key, self) =>
        //     key === self.findIndex((t) =>
        //         t.value === value.value
        //     )
        // )
        return results
    }

    async function exportExcelAsn() {
        toolbarState.isLoadingAsn = true
        toolbarState.anchorEl = null

        let results = filterHelper.filtersFlat(filterState.appliedFilters)
        let resultAsn = filterHelper.filtersFlat(asnFilters.appliedFilters)

        let body: FilterAsn = {
            ManufacturerCode: [],
            ShopCode: [],
            NumberAsn: [],
            NumberWo: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            StatusWo: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
            ColorCode: [],
            Status: [],
            ShippingDateFrom: '',
            ShippingDateTo: '',
        }
        results.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'manufacturer':
                    body.ManufacturerCode.push(x.key)
                    break
                case 'shop':
                    body.ShopCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.StatusWo.push(x.key)
                    break
                case '#WO':
                    body.NumberWo.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })
        resultAsn.forEach(x => {
            switch (x.type) {
                case 'Status':
                    if (x.key === 'Not Received' || x.key === 'Non reçu' || x.key === 'Non ricevuto') x.key = 'NonRecu'
                    else if (
                        x.key === 'Completely received' ||
                        x.key === 'Complètement reçu' ||
                        x.key === 'Completamente ricevuto'
                    )
                        x.key = 'CompletementRecu'
                    else {
                        x.key = 'PartiellementRecu'
                    }
                    body.Status.push(x.key)
                    break

                case 'NumberAsn':
                    body.NumberAsn.push(x.key)
                    break

                case 'ShippingDateFrom':
                    body.ShippingDateFrom = x.key
                    break

                case 'ShippingDateTo':
                    body.ShippingDateTo = x.key
            }
        })
        const userProfile = authToken.getAuthProfile()
        let fabricant: any
        if (userProfile) fabricant = userProfile.fabricantCode
        if (!fabricant || fabricant == 'undefined') fabricant = 'none'
        let result = await api.post<any>(`excelFile/ASN/${fabricant}`, {
            ManufacturerCode: body.ManufacturerCode,
            ShopCode: body.ShopCode,
            Status: body.Status,
            Number: body.NumberAsn,
            NumberWo: body.NumberWo,
            SeasonCode: body.SeasonCode,
            TypeWO: body.TypeWO,
            Group: body.Group,
            StatusWo: body.StatusWo,
            TypeCode: body.TypeCode,
            Gender: body.Gender,
            MaterialCode: body.MaterialCode,
            ModelCode: body.ModelCode,
            ColorCode: body.ColorCode,
            SubActivityCode: body.SubActivityCode,
            ShippingDateFrom: body.ShippingDateFrom,
            ShippingDateTo: body.ShippingDateTo,
        })

        var sampleArr = base64ToArrayBuffer(result.b64)
        let blob = new Blob([sampleArr])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Simplified Asn.xlsx'
        link.click()
        toolbarState.isLoadingAsn = false
    }

    async function exportExcelAsnDetail() {
        toolbarState.isLoadingAsn = true
        toolbarState.anchorEl = null

        let results = filterHelper.filtersFlat(filterState.appliedFilters)
        let resultAsn = filterHelper.filtersFlat(asnFilters.appliedFilters)

        let body: FilterAsn = {
            ManufacturerCode: [],
            ShopCode: [],
            NumberAsn: [],
            NumberWo: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            StatusWo: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
            ColorCode: [],
            Status: [],
            ShippingDateFrom: '',
            ShippingDateTo: '',
        }
        results.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'manufacturer':
                    body.ManufacturerCode.push(x.key)
                    break
                case 'shop':
                    body.ShopCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.StatusWo.push(x.key)
                    break
                case '#WO':
                    body.NumberWo.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })
        resultAsn.forEach(x => {
            switch (x.type) {
                case 'Status':
                    if (x.key === 'Not Received' || x.key === 'Non reçu' || x.key === 'Non ricevuto') x.key = 'NonRecu'
                    else if (
                        x.key === 'Completely received' ||
                        x.key === 'Complètement reçu' ||
                        x.key === 'Completamente ricevuto'
                    )
                        x.key = 'CompletementRecu'
                    else {
                        x.key = 'PartiellementRecu'
                    }
                    body.Status.push(x.key)
                    break

                case 'NumberAsn':
                    body.NumberAsn.push(x.key)
                    break

                case 'ShippingDateFrom':
                    body.ShippingDateFrom = x.key
                    break

                case 'ShippingDateTo':
                    body.ShippingDateTo = x.key
            }
        })
        const userProfile = authToken.getAuthProfile()
        let fabricant: any
        if (userProfile) fabricant = userProfile.fabricantCode
        if (!fabricant || fabricant == 'undefined') fabricant = 'none'
        let result = await api.post<any>(`excelFile/ASN/${fabricant}/detail`, {
            ManufacturerCode: body.ManufacturerCode,
            ShopCode: body.ShopCode,
            Status: body.Status,
            Number: body.NumberAsn,
            NumberWo: body.NumberWo,
            SeasonCode: body.SeasonCode,
            TypeWO: body.TypeWO,
            Group: body.Group,
            StatusWo: body.StatusWo,
            TypeCode: body.TypeCode,
            Gender: body.Gender,
            MaterialCode: body.MaterialCode,
            ModelCode: body.ModelCode,
            ColorCode: body.ColorCode,
            SubActivityCode: body.SubActivityCode,
            ShippingDateFrom: body.ShippingDateFrom,
            ShippingDateTo: body.ShippingDateTo,
        })

        var sampleArr = base64ToArrayBuffer(result.b64)
        let blob = new Blob([sampleArr])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Detailed Asn.xlsx'
        link.click()
        toolbarState.isLoadingAsn = false
    }

    async function exportExcel() {
        toolbarState.isLoading = true

        let results = filterHelper.filtersFlat(filterState.appliedFilters)

        let body: FiltersPost = {
            ColorCode: [],
            ManufacturerCode: [],
            ShopCode: [],
            Number: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            Status: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
        }
        results.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'manufacturer':
                    body.ManufacturerCode.push(x.key)
                    break
                case 'shop':
                    body.ShopCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.Status.push(x.key)
                    break
                case '#WO':
                    body.Number.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })

        const userProfile = authToken.getAuthProfile()
        let fabricant: any
        if (userProfile) fabricant = userProfile.fabricantCode
        let result: any
        if (!fabricant || fabricant == 'undefined') fabricant = 'none'

        result = await api.post<any>(`excelFile/${fabricant}/WO`, {
            ColorCode: body.ColorCode,
            MaterialCode: body.MaterialCode,
            ModelCode: body.ModelCode,
            TypeCode: body.TypeCode,
            ManufacturerCode: body.ManufacturerCode,
            ShopCode: body.ShopCode,
            SeasonCode: body.SeasonCode,
            TypeWO: body.TypeWO,
            Group: body.Group,
            Status: body.Status,
            Number: body.Number,
            SubActivityCode: body.SubActivityCode,
            Gender: body.Gender,
        })

        var sampleArr = base64ToArrayBuffer(result.b64)
        let blob = new Blob([sampleArr])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'WorkOrder.xlsx'
        link.click()
        toolbarState.isLoading = false
    }

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i)
            bytes[i] = ascii
        }
        return bytes
    }
    const handleClick = event => {
        runInAction(() => (toolbarState.anchorEl = event.currentTarget))
    }
    const handleClose = () => {
        runInAction(() => {
            toolbarState.anchorEl = null
        })
    }

    const handleClickFav = event => {
        runInAction(() => (toolbarState.anchorElFav = event.currentTarget))
    }
    const handleCloseFav = () => {
        runInAction(() => (toolbarState.anchorElFav = null))
    }

    let handleOpenCloseFilters = async () => {
        toolbarState.isExpended = filtersState.filtersShouldBeDisplayed && !toolbarState.isExpended
        const data = window.localStorage.getItem('MY_FAVORITES')

        if (!toolbarState.isExpended) {
            toolbarState.body = filterState.appliedFilters
            let result = filterHelper.filtersFlat(filterState.appliedFilters)
            let resultAsn = filterHelper.filtersFlat(asnFilters.appliedFilters)
            if (filterState.filterChanged || data || asnFilters.changed) {
                filterState.filterChanged = false
                asnFilters.changed = false

                let body: FilterAsn = {
                    ManufacturerCode: [],
                    ShopCode: [],
                    NumberAsn: [],
                    NumberWo: [],
                    SeasonCode: [],
                    TypeWO: [],
                    Group: [],
                    StatusWo: [],
                    SubActivityCode: [],
                    TypeCode: [],
                    Gender: [],
                    ModelCode: [],
                    MaterialCode: [],
                    ColorCode: [],
                    Status: [],
                    ShippingDateFrom: '',
                    ShippingDateTo: '',
                }
                result.forEach(x => {
                    switch (x.type) {
                        case 'color':
                            body.ColorCode.push(x.key)
                            break
                        case 'manufacturer':
                            body.ManufacturerCode.push(x.key)
                            break
                        case 'shop':
                            body.ShopCode.push(x.key)
                            break
                        case 'season':
                            body.SeasonCode.push(x.key)
                            break
                        case 'typeWO':
                            body.TypeWO.push(x.key)
                            break
                        case 'group':
                            body.Group.push(x.key)
                            break
                        case 'status':
                            body.StatusWo.push(x.key)
                            break
                        case '#WO':
                            body.NumberWo.push(x.key)
                            break
                        case 'subActivity':
                            body.SubActivityCode.push(x.key)
                            break
                        case 'type':
                            body.TypeCode.push(x.key)
                            break
                        case 'gender':
                            body.Gender.push(x.key)
                            break
                        case 'model':
                            body.ModelCode.push(x.key)
                            break
                        case 'material':
                            body.MaterialCode.push(x.key)
                            break
                    }
                })
                resultAsn.forEach(x => {
                    switch (x.type) {
                        case 'Status':
                            if (x.key === 'Not Received' || x.key === 'Non reçu' || x.key === 'Non ricevuto')
                                x.key = 'NonRecu'
                            else if (
                                x.key === 'Completely Received' ||
                                x.key === 'Complètement reçu' ||
                                x.key === 'Completamente ricevuto'
                            )
                                x.key = 'CompletementRecu'
                            else {
                                x.key = 'PartiellementRecu'
                            }
                            body.Status.push(x.key)
                            break

                        case 'NumberAsn':
                            body.NumberAsn.push(x.key)
                            break

                        case 'ShippingDateFrom':
                            body.ShippingDateFrom = x.key
                            break

                        case 'ShippingDateTo':
                            body.ShippingDateTo = x.key
                    }
                })

                // let re = await filterHelper.getFilteredAsn(body)
                // asnGlobalFiltersState.advancedShippingNoticeToDisplay = re
            }
        }
    }

    let getLinesCount = () => {
        if (document.location.href.substring(document.location.href.lastIndexOf('/') + 1) === 'listAsn')
            return asnGlobalFiltersState.advancedShippingNoticeCount
                ? asnGlobalFiltersState.advancedShippingNoticeCount
                : 0
        else return filtersState.ordreDeFabricationsCount ? filtersState.ordreDeFabricationsCount : 0
    }

    const hideClosedWoTogglerChange = event => {
        filterState.hideClosedWo = event.target.checked
    }

    const FilterIcon = !toolbarState.isExpended ? Icons.FilterList : Icons.Close
    const nbLines = getLinesCount()

    return (
        <div>
            <Accordion expanded={toolbarState.isExpended} className={classes.filterPanel}>
                <AccordionSummary className={classes.constantPanel}>
                    {filtersState.filtersShouldBeDisplayed && (
                        <div className={classes.constantPanelContent}>
                            <div className={classes.filtersZone} onClick={handleOpenCloseFilters}>
                                <h3 className={classes.heading}>
                                    <IconButton>
                                        <FilterIcon />
                                    </IconButton>
                                    {t('common.filters')}
                                </h3>
                                <h3 className={classes.filterResults}>
                                    {nbLines
                                        .toString()
                                        .concat(' ')
                                        .concat(t('common.result' + (nbLines > 1 ? 's' : '')))}
                                </h3>
                                {!toolbarState.isExpended && (
                                    <div className={classes.filtersAccumulated}>
                                        <Accumulator
                                            filtersAccumulated={filterHelper.filtersFlat(filterState.appliedFilters)}
                                            getLabelForKey={filterHelper.getLabelValue}
                                        />
                                        <AdditionalFilters />
                                    </div>
                                )}
                            </div>
                            <div className={classes.separator} onClick={handleOpenCloseFilters} />
                            <div className={classes.toolsZone}>
                                <div style={{ display: 'inline-flex' }}>
                                    <Tooltip title={t('common.save')}>
                                        <Button
                                            aria-label="Favorite"
                                            color="primary"
                                            className={classes.button}
                                            // onClick={() => saveFavorite()}
                                            style={{ alignSelf: 'center', padding: 2, minWidth: 'auto' }}>
                                            <span
                                                style={{
                                                    fontSize: '0.875rem',
                                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                    textTransform: 'none',
                                                    fontWeight: 500,
                                                }}>
                                                Favorites
                                            </span>
                                        </Button>
                                    </Tooltip>
                                    <Button
                                        variant={undefined}
                                        aria-controls="simple-menu1"
                                        aria-haspopup="true"
                                        color={'default'}
                                        disabled={toolbarState.isLoadingAsn}
                                        // onClick={e => handleClickFav(e)}
                                        style={{ padding: '0', minWidth: 'auto' }}>
                                        <Icons.KeyboardArrowDownRounded />
                                    </Button>
                                    <Menu
                                        style={{ top: '7%' }}
                                        id="simple-menu1"
                                        anchorEl={toolbarState.anchorElFav}
                                        keepMounted
                                        open={Boolean(toolbarState.anchorElFav)}
                                        onClose={() => handleCloseFav()}>
                                        <MenuItem disabled>Add Favorite Filters</MenuItem>
                                        {getFavorites().map(x => (
                                            <MenuItem onClick={filter => filterWithFavorite(x.key)}>
                                                {x.value}
                                                <Icons.DeleteRounded
                                                    onClick={key => removeStorageVariable(x.key)}
                                                    style={{ position: 'absolute', right: '1%' }}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Menu>

                                    <Menu
                                        style={{ top: '7%' }}
                                        id="simple-menu"
                                        anchorEl={toolbarState.anchorEl}
                                        keepMounted
                                        open={Boolean(toolbarState.anchorEl)}
                                        onClose={() => handleClose()}>
                                        <MenuItem onClick={() => exportExcelAsn()}>Simplified Asn</MenuItem>
                                        <MenuItem onClick={() => exportExcelAsnDetail()}> Detailed Asn</MenuItem>
                                    </Menu>
                                </div>

                                <IconButton
                                    color="primary"
                                    className={classes.button}
                                    aria-label="Refresh"
                                    onClick={() => filterHelper.reloadWantedWo()}
                                    style={{ alignSelf: 'center' }}>
                                    <Icons.Refresh />
                                </IconButton>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={filterState.hideClosedWo}
                                            onChange={hideClosedWoTogglerChange}
                                            color="primary"
                                        />
                                    }
                                    label={t('filters.hideClosedWo')}
                                    className={classes.switchClosedWo}
                                />

                                <AdditionalTools />
                                {filtersState &&
                                    filtersState.isExcel && (
                                        <Button
                                            variant={undefined}
                                            color={'default'}
                                            disabled={toolbarState.isLoading}
                                            onClick={() => exportExcel()}
                                            style={{ padding: '0px' }}>
                                            {toolbarState && toolbarState.isLoading ? (
                                                <CircularProgress />
                                            ) : (
                                                <img src="./../../../static/img/excel_icon.svg" />
                                            )}
                                        </Button>
                                    )}
                                {filtersState &&
                                    filtersState.isExcelAsn && (
                                        <div>
                                            <Button
                                                variant={undefined}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                color={'default'}
                                                disabled={toolbarState.isLoadingAsn}
                                                onClick={e => handleClick(e)}
                                                style={{ padding: '0px' }}>
                                                {toolbarState && toolbarState.isLoadingAsn ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <img src="./../../../static/img/excel_icon.svg" />
                                                )}
                                            </Button>
                                            <Menu
                                                style={{ top: '7%' }}
                                                id="simple-menu"
                                                anchorEl={toolbarState.anchorEl}
                                                keepMounted
                                                open={Boolean(toolbarState.anchorEl)}
                                                onClose={() => handleClose()}>
                                                <MenuItem onClick={() => exportExcelAsn()}>Simplified Asn</MenuItem>
                                                <MenuItem onClick={() => exportExcelAsnDetail()}>
                                                    {' '}
                                                    Detailed Asn
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                </AccordionSummary>
                <AccordionDetails>
                    <Filters handleOpenCloseFilters={handleOpenCloseFilters} />
                </AccordionDetails>
            </Accordion>
            <div
                className={toolbarState.isExpended ? classes.closeToolbarOnClickOutside : ''}
                onClick={() => {
                    if (toolbarState.isExpended) handleOpenCloseFilters()
                }}
            />
        </div>
    )
})

let styles = theme =>
    createStyles({
        constantPanel: {
            cursor: 'default !important',
        },
        constantPanelContent: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            width: '100%',
        },
        heading: {
            margin: '0',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
        filterResults: {
            margin: '0% 3%',
            whiteSpace: 'nowrap',
            fontWeight: 500,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            marginLeft: '3%',
        },
        filterPanel: {
            marginBottom: '5px',
            position: 'fixed',
            width: 'calc(100% - 240px)',
            cursor: 'default',
            zIndex: 100,
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        filtersZone: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            width: '100%',
            cursor: 'pointer',
        },
        filtersAccumulated: {
            ...defaultStyles.flexRow,
            flexWrap: 'wrap',
        },
        separator: {
            flexGrow: 1,
            cursor: 'pointer',
        },
        toolsZone: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        closeToolbarOnClickOutside: {
            position: 'fixed',
            top: '0px',
            left: '0px',
            height: '150%',
            width: '150%',
            zIndex: 99,
        },
        switchClosedWo: {
            marginLeft: '1rem',
        },
    })

export default withStyles(styles, muiOptions)(Toolbar)

const isToolbarExpended = () => toolbarState.isExpended

export { isToolbarExpended, toolbarState }
function exportExcel(): React.MouseEventHandler<HTMLElement> | undefined {
    throw new Error('Function not implemented.')
}
