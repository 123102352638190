import React, { Component } from 'react'
import { Tooltip, withStyles, createStyles, Table, TablePagination, TableRow, TableBody, Fab } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import { observable, makeAutoObservable, runInAction } from 'mobx'
import { FiltersPost, FlatOfReferenceProduit } from '../../ordreDeFabrication/models'
import api from '../../infrastructure/api'
import filtersState from '../../infrastructure/toolbar/filters/filterGlobalState'
import filterHelper from '../../infrastructure/toolbar/filters/filterHelper'
import { state } from '../../infrastructure/toolbar/filters/components/filters'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type PageNavigationType = 'firstPage' | 'nextPage' | 'prevPage' | 'lastPage'

interface IPagerState {
    items: any[]
    itemsDisplayed: any[]
    currentPage: number
}

export class PagerState<T> implements IPagerState {
    @observable items: T[]
    @observable itemsDisplayed: T[]
    @observable currentPage: number
    @observable pageCount: number

    constructor() {
        makeAutoObservable(this)
    }
}

export function CreatePagerState<T>(
    itemsCollection: T[],
    startingPage: number,
    numberOfElementPerPage: number,
    handleNavigation = false
): PagerState<T> {
    let newState = new PagerState<T>()
    const itemLength = handleNavigation ? itemsCollection.length : filtersState.ordreDeFabricationsCount
    newState.items = itemsCollection
    newState.itemsDisplayed = itemsCollection
    newState.currentPage = startingPage
    newState.pageCount = Math.ceil(itemLength / numberOfElementPerPage)

    return newState
}

class PagerProps<T> {
    numberOfElementPerPage: number
    pagerState: PagerState<T>
    handleNavigation?: () => void
}

@observer
class Pager<T> extends Component<MuiProps & PagerProps<T>, {}> {
    componentDidMount() {
        this.props.pagerState.pageCount = Math.ceil(
            filtersState.ordreDeFabricationsCount / this.props.numberOfElementPerPage,
        )
    }

    async UNSAFE_componentWillUpdate(nextProps: MuiProps & PagerProps<T>) {
        if (filtersState.ordreDeFabricationsCount !== 0 && nextProps.pagerState.itemsDisplayed.length === 0 && !this.props.handleNavigation) {
            {
                let result = filterHelper.filtersFlat(state.appliedFilters)

                let body: FiltersPost = {
                    ColorCode: [],
                    ManufacturerCode: [],
                    ShopCode: [],
                    Number: [],
                    SeasonCode: [],
                    TypeWO: [],
                    Group: [],
                    Status: [],
                    SubActivityCode: [],
                    TypeCode: [],
                    Gender: [],
                    ModelCode: [],
                    MaterialCode: [],
                }
                result.forEach(x => {
                    switch (x.type) {
                        case 'color':
                            body.ColorCode.push(x.key)
                            break
                        case 'manufacturer':
                            body.ManufacturerCode.push(x.key)
                            break
                        case 'shop':
                            body.ShopCode.push(x.key)
                            break
                        case 'season':
                            body.SeasonCode.push(x.key)
                            break
                        case 'typeWO':
                            body.TypeWO.push(x.key)
                            break
                        case 'group':
                            body.Group.push(x.key)
                            break
                        case 'status':
                            body.Status.push(x.key)
                            break
                        case '#WO':
                            body.Number.push(x.key)
                            break
                        case 'subActivity':
                            body.SubActivityCode.push(x.key)
                            break
                        case 'type':
                            body.TypeCode.push(x.key)
                            break
                        case 'gender':
                            body.Gender.push(x.key)
                            break
                        case 'model':
                            body.ModelCode.push(x.key)
                            break
                        case 'material':
                            body.MaterialCode.push(x.key)
                            break
                    }
                })

                await filterHelper.loadWantedWo(body, this.props.pagerState.currentPage + 1)
                let ofs = filterHelper.filterOpenedWo(
                    filtersState.ordreDeFabricationsTotal.map(x => jQuery.extend({}, x)),
                )
                filtersState.ordreDeFabricationsToDisplay = ofs
            }
        }
    }

    handleNavigationInPages(type: PageNavigationType) {
        let goFirstPage = () => (this.props.pagerState.currentPage = 0)

        let goLastPage = () => (this.props.pagerState.currentPage = this.props.pagerState.pageCount - 1)

        let goNextPage = () => {
            this.props.pagerState.currentPage = this.props.pagerState.currentPage + 1
        }

        let goPrevPage = () => (this.props.pagerState.currentPage = this.props.pagerState.currentPage - 1)

        switch (type) {
            case 'firstPage':
                goFirstPage()
                break
            case 'nextPage':
                goNextPage()
                break
            case 'prevPage':
                goPrevPage()
                break
            case 'lastPage':
                goLastPage()
                break
        }
        runInAction(() => (this.props.pagerState.itemsDisplayed = []))
        window.scroll(0, 0)
        if(this.props.handleNavigation) this.props.handleNavigation()
    }

    onChangePage = (event: any, newPage: number) => {
        if (event !== null) {
            this.props.pagerState.currentPage = newPage
        }
    }

    tablePaginationActions = () => {
        let classes = this.props.classes
        let currentPage = this.props.pagerState.currentPage
        return (
            <div className={classes.pagerNav}>
                <Tooltip title={t('pager.firstPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('firstPage')}
                            disabled={currentPage === 0}
                            className={classes.pagerButton}>
                            <Icons.FirstPage />
                        </Fab>
                    </div>
                </Tooltip>
                <Tooltip title={t('pager.prevPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('prevPage')}
                            disabled={currentPage === 0}
                            className={classes.pagerButton}>
                            <Icons.KeyboardArrowLeft />
                        </Fab>
                    </div>
                </Tooltip>
                <Tooltip title={t('pager.nextPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('nextPage')}
                            disabled={currentPage >= this.props.pagerState.pageCount - 1}
                            className={classes.pagerButton}>
                            <Icons.KeyboardArrowRight />
                        </Fab>
                    </div>
                </Tooltip>
                <Tooltip title={t('pager.lastPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('lastPage')}
                            disabled={currentPage >= this.props.pagerState.pageCount - 1}
                            className={classes.pagerButton}>
                            <Icons.LastPage />
                        </Fab>
                    </div>
                </Tooltip>
            </div>
        )
    }

    labelRowsDisplayed = (current, total) => {
        return (
            <div className={this.props.classes.labelRowsDisplayed}>{`${t('pager.page')} : ${current} / ${total}`}</div>
        )
    }

    render() {
        let classes = this.props.classes
        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <TablePagination
                            className={classes.paginationRow}
                            classes={{ spacer: classes.paginationSpacer }}
                            count={this.props.pagerState.items.length}
                            page={this.props.pagerState.currentPage}
                            rowsPerPage={this.props.numberOfElementPerPage}
                            rowsPerPageOptions={[this.props.numberOfElementPerPage]}
                            onPageChange={(event, page) => {
                                this.onChangePage(event, page)
                            }}
                            ActionsComponent={this.tablePaginationActions}
                            labelDisplayedRows={() =>
                                this.labelRowsDisplayed(
                                    this.props.pagerState.currentPage + 1,
                                    this.props.pagerState.pageCount,
                                )
                            }
                        />
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
}

let styles = theme =>
    createStyles({
        pagerNav: {
            display: 'flex',
            width: '22%',
            justifyContent: 'space-between',
            marginLeft: '2%',
        },
        pagerButton: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
        labelRowsDisplayed: {
            fontSize: '1.2rem',
            width: '150px',
        },
        paginationRow: {
            borderBottom: 'none',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        paginationSpacer: {
            display: 'none',
        },
    })

export default withStyles(styles, muiOptions)(Pager)
