import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import { observable, observe, makeAutoObservable, runInAction, toJS } from 'mobx'
import {
    Button,
    withStyles,
    createStyles,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    Typography,
    Fade,
    CircularProgress,
    SnackbarContent,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import api from '../../infrastructure/api'
import LigneProduitQuantitesAsn from './_ligneProduitQuantitesAsn'
import { MuiProps, muiOptions, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import filtersState from '../../infrastructure/toolbar/filters/filterGlobalState'
import { DraftAdvancedShippingNotice, DeclarerExpedition, MagasinReception } from '../models'
import filterHelper from '../../infrastructure/toolbar/filters/filterHelper'
import { FilterType } from '../../infrastructure/toolbar/filters/models'
import { showNotification, state as notificationsState } from '../../infrastructure/notifications'
import { OfAsn } from '../models'
import { navigateTo } from '../../infrastructure/navigation'
import { t } from 'i18next'
import { replaceAdditionalFilters } from '../../infrastructure/toolbar/additionalFilters'
import { replaceAdditionalTools } from '../../infrastructure/toolbar/additionalTools'
import Pager, { PagerState, CreatePagerState } from '../../common/pagination/pager'
import Stepper from './stepper'
import { Steps } from '../models'
import { FiltersPost, OrdreDeFabrication } from '../../ordreDeFabrication/models'
import { ExcelButton } from '../../infrastructure/excel/excelShippmentCreator'
import authToken from '../../infrastructure/authToken'
import { state as componentFiltersState } from '../../infrastructure/toolbar/filters/components/filters'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import Snackbar from '../../infrastructure/snackbar'

class AsnDraftState {
    @observable filteredDrafts: DraftAdvancedShippingNotice = { ofAsns: [], totalPretAExpedier: 0, id: '' }
    @observable validationDraft: DraftAdvancedShippingNotice = { ofAsns: [], totalPretAExpedier: 0, id: '' }
    @observable readyToShipQuantity: { produitId: string; size: string; quantity: number }[] = []
    @observable activeStep: Steps = Steps.Prepare
    @observable fabricant: string = ''
    @observable pagerState: PagerState<OfAsn>
    @observable selectedMagasin: MagasinReception
    @observable showSpinner: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new AsnDraftState()

@observer
class AsnDraft extends Component<MuiProps, {}> {
    shouldPagersBeDisplayed: boolean = false
    numberOfItemsByPage: number = 20
    magasinsReceptions: MagasinReception[] = []

    ofAsnToDisplayObserver = observe(state, 'filteredDrafts', change => {
        if (componentFiltersState.filterChanged) state.pagerState.currentPage = 0
        this.initPagerState(state.filteredDrafts.ofAsns)
    })

    filteredOrdreDeFabrications = observe(filtersState, 'filters', async _ => {
            try {
                state.showSpinner = true
                await this.loadMagasinsReception()
            } finally {
                state.showSpinner = false
            }
    })

    UNSAFE_componentWillMount() {
        this.initPagerState([])
        this.loadMagasinsReception()
    }

    async componentDidMount() {
        runInAction(() => {
            filtersState.isExcel = false
            filtersState.isExcelAsn = false
        })

        let currentPageCount = Math.ceil(state.filteredDrafts.ofAsns.length / this.numberOfItemsByPage)
        if (currentPageCount != state.pagerState.pageCount) state.pagerState.currentPage = 0
        replaceAdditionalFilters()
        replaceAdditionalTools()
        if (this.checkFabricantFilterSelected()) await this.loadOrdresDeFabrications()
    }

    componentWillUnmount() {
        filterHelper.setFilterVisibility(true)
        this.filteredOrdreDeFabrications()
        this.ofAsnToDisplayObserver()
    }

    async UNSAFE_componentWillUpdate() {
        filterHelper.setFilterVisibility(state.activeStep == Steps.Prepare)
    }

    async loadMagasinsReception() {
        this.magasinsReceptions = await api.get<MagasinReception[]>('asn/magasinReceptions')
        if(filtersState.filters?.shop){
            this.magasinsReceptions = this.magasinsReceptions.filter(x => filtersState.filters.shop.some(y => y.code == x.code))
            const appliedFilters = toJS(componentFiltersState.appliedFilters)
            if(appliedFilters.shop){
                const shops = Object.keys(appliedFilters.shop)
                this.magasinsReceptions = this.magasinsReceptions.filter(x => shops.some(y => y == x.code))   
            }
        }
        state.selectedMagasin = this.magasinsReceptions.first(x => x.isPrincipal)
        if(!state.selectedMagasin) state.selectedMagasin = this.magasinsReceptions[0]
        this.checkIfOnlyOneMagasin()
        if(state.selectedMagasin){
            if (this.checkFabricantFilterSelected()) await this.loadOrdresDeFabrications()
        }else{
            state.filteredDrafts = {...state.filteredDrafts, ofAsns: [], totalPretAExpedier: 0}
        }
    }

    checkFabricantFilterSelected() {
        let filteredFabricants = filterHelper.getFiltersOfType(FilterType.fabricant)
        if (filteredFabricants.length == 1) {
            state.fabricant = filteredFabricants[0]
            return true
        } else if (Object.keys(filtersState.filtersData.fabricant).length == 1) {
            state.fabricant = Object.keys(filtersState.filtersData.fabricant)[0]
            return true
        } else {
            state.fabricant = ''
            state.filteredDrafts = { ofAsns: [], totalPretAExpedier: 0, id: '' }
            return false
        }
    }

    checkIfOnlyOneMagasin() {
        let magasinCounter = filtersState.ordreDeFabricationsToDisplay.reduce(
            (acc: { [MagasinCode: string]: number }, current: OrdreDeFabrication) => {
                current.referenceProduits.map(x => x.magasinCode).forEach(code => {
                    if (!acc[code]) acc[code] = 0
                    acc[code]++
                })
                return acc
            },
            {},
        )

        let magasinCodes = Object.keys(magasinCounter)

        if (magasinCodes.length == 1)
            state.selectedMagasin = this.magasinsReceptions.first(x => x.code == magasinCodes[0])
    }

    async loadOrdresDeFabrications(withPageReset = false) {
        state.showSpinner = true;
        
        const selectedMagasin = state.selectedMagasin ? state.selectedMagasin.code : ''

        let filters = filterHelper.filtersFlat(componentFiltersState.appliedFilters)

        let body: FiltersPost = {
            ColorCode: [],
            ManufacturerCode: [],
            ShopCode: [],
            Number: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            Status: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
        }
        filters.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.Status.push(x.key)
                    break
                case '#WO':
                    body.Number.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })
       
        let result = await api.post<FiltersPost>(`asn/${state.fabricant}/draft/${selectedMagasin}`, body)
        
        if(selectedMagasin === state.selectedMagasin?.code){
            state.filteredDrafts = result;
            state.showSpinner= false;
            if(withPageReset){
                state.pagerState.currentPage = 0
                this.initPagerState(result.ofAsns)
            }
        }
    }

    initPagerState(asnToDisplay: OfAsn[]) {
        let oldPageNumber = !!state.pagerState ? state.pagerState.currentPage : 0

        this.shouldPagersBeDisplayed = asnToDisplay.length > this.numberOfItemsByPage

        state.pagerState = CreatePagerState<OfAsn>(asnToDisplay, oldPageNumber, this.numberOfItemsByPage,true)
    }

    async goToValidateDraft() {
        let quantityIsPositive = (quantity: number | null) => (quantity || 0) >= 0
        let areAllBooleanTrue = (bools: boolean[]) =>
            bools.reduce((acc: boolean, current: boolean) => acc && current, true)
        let isOfAsnCorrect = (ofAsn: OfAsn) =>
            areAllBooleanTrue(ofAsn.resteAPreparer.quantites.map(y => quantityIsPositive(y.quantite)))
        state.validationDraft = await api.get<DraftAdvancedShippingNotice>(
            `asn/${state.fabricant}/draft/${state.selectedMagasin ? state.selectedMagasin.code : ''}/validation`,
        )
        state.filteredDrafts.totalPretAExpedier = state.validationDraft.totalPretAExpedier
        if (state.validationDraft.ofAsns.length > 0) {
            let areAllInputCorrect = areAllBooleanTrue(state.validationDraft.ofAsns.map(isOfAsnCorrect))

            if (areAllInputCorrect) {
                state.activeStep = Steps.Checking
            } else {
                showNotification(t('Message.quantityIsNotCorrect'), 'error')
            }
        } else {
            // showNotification(t('Message.noQuantityToValidate'), 'error')
        }
    }

    goBackToPreparingShipping() {
        state.activeStep = Steps.Prepare
        this.loadOrdresDeFabrications()
    }

    handleChangeMagasinReception = async event => {
        var magasinCode = event.target.value
        state.selectedMagasin = this.magasinsReceptions.first(x => x.code == magasinCode)
        if (this.checkFabricantFilterSelected()) await this.loadOrdresDeFabrications(true)
    }

    handleNavigationChange = () =>{
        this.initPagerState(state.filteredDrafts.ofAsns);
    }

    async createNewAsn() {
        try {
            const userProfile = authToken.getAuthProfile()
            state.showSpinner = true
            await api.post<DeclarerExpedition>('asn/declarer', {
                AdvancedShippingNoticeId: state.validationDraft.id,
                Username: userProfile ? userProfile.firstName + ' ' + userProfile.lastName : '',
            })
            await this.loadOrdresDeFabrications()
            state.activeStep = Steps.Prepare
            await navigateTo(`/detailsAsn/${state.validationDraft.numero}`)
            showNotification(t('Message.createSuccessASN'), 'success')
            // await filterHelper.reloadWantedWo()
        } catch (e) {
            showNotification(e.message, 'error')
        } finally {
            state.showSpinner = false
        }
    }

    render() {
        let classes = this.props.classes
        if (state.fabricant == '')
            return (
                <SnackbarContent
                    message={
                        <span className={classes.snackbarContent}>
                            <Icons.Warning className={classes.icon} />
                            {t('Message.mustFilterManufacturer')}
                        </span>
                    }
                    className={classes.requiredFilterMessage}
                />
            )
        let pageIsPrepare = state.activeStep == Steps.Prepare
        return (
            <Fragment>
                <div className={classes.prepareAsnHeader}>
                    <Stepper activeStep={state.activeStep} />
                    <div className={classes.magasinRootContainer}>
                        <Icons.Store className={classes.magasinIcon} />
                        {pageIsPrepare ? (
                            <FormControl className={classes.inputLabel}>
                                <InputLabel>{t('asn.magasinReception')}</InputLabel>
                                <Select
                                    value={state.selectedMagasin ? state.selectedMagasin.code : ''}
                                    onChange={this.handleChangeMagasinReception}
                                    classes={{ root: classes.selectRoot }}
                                    MenuProps={{
                                        variant: "menu",
                                    }}>
                                    {this.magasinsReceptions.map(x => (
                                        <MenuItem key={x.code} value={x.code}>
                                            {x.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <div>
                                <Typography className={classes.magasinReception} variant="subtitle1">
                                    {t('asn.magasinReception')}
                                </Typography>
                                <Typography className={classes.magasinReceptionLabel} variant="subtitle1">
                                    {state.selectedMagasin.label}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <div className={classes.totalReadyCounter}>
                        <Typography className={classes.counterLabel} variant="subtitle1">
                            {t('asn.planAsn.totalReadyToShip')}
                        </Typography>
                        <Typography className={classes.counterValue} variant="subtitle1">
                            {state.filteredDrafts.totalPretAExpedier}
                        </Typography>
                    </div>
                    {pageIsPrepare ? (
                        <div className={classes.asnButtonZone}>
                            <Button
                                variant="contained"
                                className={classes.buttonChecking}
                                onClick={() => this.goToValidateDraft()}>
                                {t('asn.planAsn.checking')}
                                <Icons.NavigateNext />
                            </Button>
                        </div>
                    ) : (
                        <div className={classes.asnButtonZone}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {ExcelButton(
                                    state.validationDraft,
                                    state.selectedMagasin.label,
                                    { code: state.fabricant, label: state.fabricant },
                                    'Shipment',
                                    'Shipment',
                                )}
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Button
                                        variant="contained"
                                        className={classes.buttonBack}
                                        onClick={() => this.goBackToPreparingShipping()}>
                                        <Icons.NavigateBefore />
                                        {t('asn.planAsn.back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.buttonCreate}
                                        onClick={() => this.createNewAsn()}
                                        disabled={state.showSpinner}>
                                        {t('asn.planAsn.createAsn')}
                                        <Icons.NavigateNext />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Fade in={state.showSpinner} unmountOnExit>
                    <CircularProgress className={classes.spinner} size={20} />
                </Fade>
                { !state.showSpinner && state.pagerState.itemsDisplayed.length === 0 && (
                        <Typography className={classes.noItemsFound}>{t('Message.noOfToDisplay')}</Typography>
                )}
                {pageIsPrepare &&
                    this.shouldPagersBeDisplayed && (
                        <Pager numberOfElementPerPage={this.numberOfItemsByPage} pagerState={state.pagerState} handleNavigation={this.handleNavigationChange}/>
                    )}
                {pageIsPrepare && !!state.pagerState
                    ? state.pagerState.itemsDisplayed.slice(state.pagerState.currentPage * this.numberOfItemsByPage, (state.pagerState.currentPage + 1) * this.numberOfItemsByPage).map(x => (
                          <LigneProduitQuantitesAsn
                              key={x.ordreDeFabricationNumber.concat(x.produit.code)}
                              ofAsn={x}
                              fabricant={state.fabricant}
                              filteredDrafts={state.filteredDrafts}
                              editable={true}
                              magasinCode={state.selectedMagasin?.code || ''}
                          />
                      ))
                    : state.validationDraft.ofAsns.map(x => (
                          <LigneProduitQuantitesAsn
                              key={x.ordreDeFabricationNumber.concat(x.produit.code)}
                              ofAsn={x}
                              fabricant={state.fabricant}
                              editable={false}
                              magasinCode={state.selectedMagasin?.code || ''}
                          />
                      ))}
                {pageIsPrepare &&
                    this.shouldPagersBeDisplayed && (
                        <Pager numberOfElementPerPage={this.numberOfItemsByPage} pagerState={state.pagerState} handleNavigation={this.handleNavigationChange}/>
                    )}
                {notificationsState
                    .getNotifications()
                    .map(notif => <Snackbar message={notif.message} variant={notif.variant} key={notif.key} />)}
            </Fragment>
        )
    }
}

let styles = theme =>
    createStyles({
        requiredFilterMessage: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: defaultColors.amber.dark.color,
        },
        snackbarContent: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
        },
        icon: {
            opacity: 0.9,
            marginRight: 8,
            fontSize: 20,
        },
        prepareAsnHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        asnButtonZone: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '25%',
        },
        buttonChecking: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonAction as CSSProperties),
        },
        buttonBack: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonAnnulation as CSSProperties),
        },
        buttonCreate: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonAction as CSSProperties),
        },
        totalReadyCounter: {
            ...defaultStyles.flexColumn,
            justifyContent: 'center',
            alignItems: 'center',
        },
        counterLabel: {
            textAlign: 'center',
        },
        counterValue: {
            fontWeight: 'bold',
            fontSize: '2rem',
        },
        magasinRootContainer: {
            ...defaultStyles.flexColumn,
            alignItems: 'center',
        },
        inputLabel: {
            minWidth: '90px',
        },
        magasinReceptionLabel: {
            textAlign: 'center',
            fontWeight: 'bold',
        },
        magasinReception: {
            textAlign: 'center',
            fontSize: '0.8rem',
            color: defaultColors.grey.light.text,
        },
        selectRoot: {
            fontSize: '0.8rem',
        },
        magasinIcon: {
            width: '2em',
            height: '2em',
            color: defaultColors.primary.dark.color,
        },
        spinner: {
            ...defaultStyles.spinner,
        },
        noItemsFound: {
            backgroundColor: "#242527",
            color: 'white',
            width:'100%',
            paddingTop: '2em',
            paddingBottom: '2em',
            textAlign: 'center'
        }
    })

export default withStyles(styles, muiOptions)(AsnDraft)