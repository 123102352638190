import React, { Component, Fragment } from 'react'
import api from '../../../infrastructure/api'
import { observable, observe, makeAutoObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { List, ListItem, Paper, withStyles, createStyles, Button, Fade, CircularProgress } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import {
    MuiProps,
    muiOptions,
    customStyledComponent,
    defaultColors,
    defaultStyles,
} from '../../../infrastructure/materialUiThemeProvider'
import LigneParTaille from './_ligneParTaille'
import LigneParStatut from './_ligneParStatut'
import filtersState from '../../../infrastructure/toolbar/filters/filterGlobalState'
import { replaceAdditionalFilters } from '../../../infrastructure/toolbar/additionalFilters'
import { replaceAdditionalTools } from '../../../infrastructure/toolbar/additionalTools'
import filterHelper from '../../../infrastructure/toolbar/filters/filterHelper'
import { state as componentFiltersState } from '../../../infrastructure/toolbar/filters/components/filters'

import {
    LastCommentaireReferenceProduit,
    OrdreDeFabrication,
    ReferenceProduit,
    FlatOfReferenceProduit,
    FiltersPost,
} from '../../models'
import Pager, { PagerState, CreatePagerState } from '../../../common/pagination/pager'
import { state as notificationsState } from '../../../infrastructure/notifications'
import Snackbar from '../../../infrastructure/snackbar'

enum ManageOrdreDeFabricationScreens {
    Statut,
    Taille,
    Grid,
}

class ListeOrdresDeFabricationState {
    @observable screenToDisplay: ManageOrdreDeFabricationScreens
    @observable lastCommentaires: LastCommentaireReferenceProduit[]
    @observable pagerState: PagerState<FlatOfReferenceProduit>

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new ListeOrdresDeFabricationState()

@observer
class ListeOrdresDeFabrication extends Component<MuiProps, {}> {
    shouldPagersBeDisplayed: boolean = false
    numberOfItemsByPage: number = 20

    ordreDeFabricationToDisplayObserver = observe(filtersState, 'ordreDeFabricationsToDisplay', change => {
        if (componentFiltersState.filterChanged) {
            state.pagerState.currentPage = 0
        }
        this.initPagerState(filtersState.ordreDeFabricationsToDisplay)
    })

    async componentDidMount() {
        runInAction(() => {
            filtersState.isExcel = true
            filtersState.isExcelAsn = false
        })

        this.initPagerState(filtersState.ordreDeFabricationsToDisplay)
        runInAction(() => (state.screenToDisplay = ManageOrdreDeFabricationScreens.Taille))

        replaceAdditionalFilters()
        replaceAdditionalTools(this.localCustomToolbar())
        const getLastCommentaires = api.get<LastCommentaireReferenceProduit[]>('commentaire/last')
        // const data = window.localStorage.getItem('MY_FAVORITES');

        // let result = filterHelper.filtersFlat(componentFiltersState.appliedFilters)

        // let body: FiltersPost = {
        //     ColorCode: [],
        //     ManufacturerCode: [],
        //     Number: [],
        //     SeasonCode: [],
        //     TypeWO: [],
        //     Group: [],
        //     Status: [],
        //     SubActivityCode: [],
        //     TypeCode: [],
        //     Gender: [],
        //     ModelCode: [],
        //     MaterialCode: []
        // }
        // result.forEach(x => {
        //     switch (x.type) {
        //         case 'color':
        //             body.ColorCode.push(x.key)
        //             break
        //         case 'manufacturer':
        //             body.ManufacturerCode.push(x.key)
        //             break
        //         case 'season':
        //             body.SeasonCode.push(x.key)
        //             break
        //         case 'typeWO':
        //             body.TypeWO.push(x.key)
        //             break
        //         case 'group':
        //             body.Group.push(x.key)
        //             break
        //         case 'status':
        //             body.Status.push(x.key)
        //             break
        //         case '#WO':
        //             body.Number.push(x.key)
        //             break
        //         case 'subActivity':
        //             body.SubActivityCode.push(x.key)
        //             break
        //         case 'type':
        //             body.TypeCode.push(x.key)
        //             break
        //         case 'gender':
        //             body.Gender.push(x.key)
        //             break
        //         case 'model':
        //             body.ModelCode.push(x.key)
        //             break
        //         case 'material':
        //             body.MaterialCode.push(x.key)
        //             break
        //     }
        // })
        // filterHelper.loadWantedWo(body)
        state.lastCommentaires = await getLastCommentaires
    }

    initPagerState(ordreDeFabricationsToDisplay: OrdreDeFabrication[]) {
        const oldPageNumber = !!state.pagerState ? state.pagerState.currentPage : 0
        const ofReferenceProduits: {
            referenceProduit: ReferenceProduit
            of: OrdreDeFabrication
        }[] = ordreDeFabricationsToDisplay
            .map(x => ({
                of: x,
                referenceProduits: x.referenceProduits,
            }))
            .reduce(
                (
                    acc: FlatOfReferenceProduit[],
                    current: {
                        of: OrdreDeFabrication
                        referenceProduits: ReferenceProduit[]
                    },
                ) =>
                    acc.concat(
                        current.referenceProduits.map(referenceProduit => ({
                            of: current.of,
                            referenceProduit: referenceProduit,
                        })),
                    ),
                [],
            )

        this.shouldPagersBeDisplayed = filtersState.ordreDeFabricationsCount > this.numberOfItemsByPage

        runInAction(
            () =>
                (state.pagerState = CreatePagerState<FlatOfReferenceProduit>(
                    ofReferenceProduits,
                    oldPageNumber,
                    this.numberOfItemsByPage,
                )),
        )
    }

    changeScreenToDisplay(newScreen: ManageOrdreDeFabricationScreens) {
        state.screenToDisplay = newScreen
        replaceAdditionalTools(this.localCustomToolbar())
    }

    customToolbarContainer = customStyledComponent(Paper)(
        {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
        },
        muiOptions,
    )

    localCustomToolbar = () => (
        <this.customToolbarContainer>
            <Button
                key={ManageOrdreDeFabricationScreens.Taille}
                variant={state.screenToDisplay == ManageOrdreDeFabricationScreens.Taille ? 'contained' : undefined}
                color={state.screenToDisplay == ManageOrdreDeFabricationScreens.Taille ? 'primary' : 'default'}
                onClick={() => this.changeScreenToDisplay(ManageOrdreDeFabricationScreens.Taille)}>
                <Icons.ArtTrack />
            </Button>
            <Button
                key={ManageOrdreDeFabricationScreens.Statut}
                variant={state.screenToDisplay == ManageOrdreDeFabricationScreens.Statut ? 'contained' : undefined}
                color={state.screenToDisplay == ManageOrdreDeFabricationScreens.Statut ? 'primary' : 'default'}
                onClick={() => this.changeScreenToDisplay(ManageOrdreDeFabricationScreens.Statut)}>
                <Icons.List />
            </Button>
        </this.customToolbarContainer>
    )

    render() {
        const classes = this.props.classes
        const screen = state.screenToDisplay
        return (
            <div>
                {this.shouldPagersBeDisplayed && (
                    <Pager numberOfElementPerPage={this.numberOfItemsByPage} pagerState={state.pagerState} />
                )}
                <Fade in={componentFiltersState.loadWoIsRunning} unmountOnExit>
                    <CircularProgress className={classes.spinner} size={20} />
                </Fade>
                <List className={classes.ordreDeFabricationList} disablePadding={true}>
                    {!!state.pagerState &&
                        state.pagerState.itemsDisplayed.map((x, index) => (
                            <ListItem
                                key={index + ' ordreFabricationView'}
                                className={classes.cardItem}
                                disableGutters={true}>
                                <Paper className={classes.backgroundItemPaper}>
                                    {screen == ManageOrdreDeFabricationScreens.Taille ? (
                                        <LigneParTaille
                                            of={x.of}
                                            referenceProduit={x.referenceProduit}
                                            lastCommentaire={
                                                !!state.lastCommentaires
                                                    ? state.lastCommentaires.first(
                                                          y => y.referenceProduitId == x.referenceProduit.id,
                                                      )
                                                    : undefined
                                            }
                                        />
                                    ) : screen == ManageOrdreDeFabricationScreens.Statut ? (
                                        <LigneParStatut
                                            of={x.of}
                                            referenceProduit={x.referenceProduit}
                                            lastCommentaire={
                                                !!state.lastCommentaires
                                                    ? state.lastCommentaires.first(
                                                          y => y.referenceProduitId == x.referenceProduit.id,
                                                      )
                                                    : undefined
                                            }
                                        />
                                    ) : null}
                                </Paper>
                            </ListItem>
                        ))}
                </List>
                {this.shouldPagersBeDisplayed && (
                    <Pager numberOfElementPerPage={this.numberOfItemsByPage} pagerState={state.pagerState} />
                )}
                {notificationsState
                    .getNotifications()
                    .map(notif => <Snackbar message={notif.message} variant={notif.variant} key={notif.key} />)}
            </div>
        )
    }
}

const styles = theme =>
    createStyles({
        backgroundItemPaper: {
            display: 'block',
            width: '100%',
        },
        listHeader: {
            ...defaultStyles.flexRow,
            width: '86.5%',
            marginTop: '-2px',
            marginLeft: '-25px',
            position: 'fixed',
            zIndex: 2,
            top: '120px',
            right: '19px',
            backgroundColor: defaultColors.grey.light.color,
            color: defaultColors.grey.light.text,
        },
        ordreDeFabricationList: {
            marginTop: '20px',
        },
        cardItem: {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
        spinner: {
            ...defaultStyles.spinner,
        },
    })

export default withStyles(styles, muiOptions)(ListeOrdresDeFabrication)
